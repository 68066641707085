﻿/**
 *  @ngdoc controller
 *  @name modify reason Directive
 *  @description Mdify Directive
 */
(function () {
    "use:strict";

    angular.module("myApp").directive('modifyreasonModel', function ($timeout) {
        return {
            controller: 'hoaController',
            restrict: 'E',
            scope: {
                modelId: "@",
                data: '='
            },
            templateUrl: 'app/directive/modifyReason/modifyreasondir.html'
        }
    }).controller('modifyreasonController', function ($scope, eyeResource) {

    });

}(window.angular));